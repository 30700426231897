<template>
  <div class="personal-header">
    <img src="../../../assets/no-avatar.png" alt="" srcset="" class="ava" v-if="!userInfo.avatar" />
    <img :src="userInfo.avatar" alt="" srcset="" class="ava" v-else />
    <div class="personal-information">
      <div class="personal-nickname">
        <div class="nickname">{{ userInfo.name }}</div>
        <div class="userType" v-if="userType === 'companyUser'">企业管理员</div>
      </div>
      <div class="personal-company">{{ userInfo.companyName }}</div>
    </div>
  </div>
</template>

<script>
import { getUserType, getUserInfo } from '@/utils/user.js'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'PersonalHeader',
  computed: {
    ...mapGetters(['userInfo', 'userType'])
  },
  created() {
    this.getCookieInfo()
  },
  methods: {
    ...mapActions({
      set_userType: 'SET_USERTYPE',
      set_userInfo: 'SET_USERINFO'
    }),
    getCookieInfo() {
      if (getUserType()) {
        this.set_userType(getUserType())
      }
      if (getUserInfo()) {
        this.set_userInfo(JSON.parse(getUserInfo()))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.personal-header {
  box-sizing: border-box;
  width: 100%;
  height: 100px;
  background: url('../../../assets/personal-bac.png') no-repeat center;
  background-size: 100% 100%;
  margin-top: 12px;
  padding: 20px 0 20px 26px;
  display: flex;
  align-items: center;
  .ava {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .personal-information {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .personal-nickname {
      display: flex;
      align-items: center;
      margin-bottom: 2px;
      .nickname {
        font-size: 16px;
        font-weight: bold;
        color: #373c41;
      }
      .userType {
        padding: 1px 8px 1px 9px;
        border-radius: 12px;
        border: 1px solid #ff5500;
        background: #fef4eb;
        font-size: 12px;
        font-weight: 400;
        color: #ff5500;
        margin-left: 12px;
      }
    }
    .personal-company {
      font-size: 12px;
      font-weight: 400;
      color: #373c41;
    }
  }
}
@media screen and (min-width: 768px) {
  .personal-header {
    max-width: 1200px;
    height: 138px;
    margin: 12px auto 0;
    background: url('../../../assets/personal-bac-web.png') no-repeat center;
    padding: 25px 0 25px 54px;
    .ava {
      width: 88px;
      height: 88px;
      margin-right: 22px;
    }
    .personal-information {
      .personal-nickname {
        margin-bottom: 5px;
        .nickname {
          font-size: 20px;
        }
        .userType {
          padding: 1px 12px 1px 12px;
          border-radius: 14px;
          margin-left: 28px;
        }
      }
      .personal-company {
        font-size: 14px;
      }
    }
  }
}
</style>
