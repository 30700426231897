<template>
  <div class="personal-content">
    <div :class="equipmentType === 'WEB' ? '' : 'select-tab-box'">
      <select-tab :tabList="tabList" @changeTab="changeTab" :selectTabValue="selectTabValue"></select-tab>
    </div>
    <component :is="componentValue" :selectTabValue="selectTabValue" @value-updated="onUpdate"></component>
  </div>
</template>

<script>
import SelectTab from '../components/SelectTab/index.vue'
import CourseAll from './courseAll/index.vue'
import Order from './Order'
import OrderDetail from './Order/orderDetail'
import Voucher from './voucher'
import { getCountOfUser, getCountByUser } from '@/api/personalCenter.js'
import { mapGetters } from 'vuex'
export default {
  name: 'PersonalContent',
  data() {
    return {
      tabList: [
        {
          label: '我的课程',
          value: 'CourseAll',
          number: 12
        },
        {
          label: '我的兑换券',
          value: 'Voucher'
        },
        {
          label: '我的订单',
          value: 'Order'
        }
      ],
      selectTabValue: 'CourseAll',
      componentValue: 'CourseAll'
    }
  },
  watch: {
    userType: {
      handler: function (newVal, oldVal) {
        console.log('newVal', newVal)
        if (newVal == 'companyUser') {
          this.tabList = [
            {
              label: '我的课程',
              value: 'CourseAll',
              number: 12
            },
            {
              label: '我的订单',
              value: 'Order'
            }
          ]
        } else {
          this.tabList = [
            {
              label: '我的课程',
              value: 'CourseAll',
              number: 12
            },
            {
              label: '我的兑换券',
              value: 'Voucher'
            },
            {
              label: '我的订单',
              value: 'Order'
            }
          ]
        }
      },
      immediate: true
    },
    equipmentType: {
      handler: function (newVal, oldVal) {
        if (newVal === 'APP') {
          if (this.componentValue == 'OrderDetail') {
            this.$router.push({
              path: '/orderDetail',
              query: {
                orderId: this.$route.query.orderId
              }
            })
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.getCountByUser()
  },
  methods: {
    changeTab(selectValue) {
      let { value } = selectValue
      this.selectTabValue = value
      this.componentValue = value
    },
    // 查询兑换券的数量
    async getCountOfUser() {
      try {
        let res = await getCountOfUser()
      } catch (error) {}
    },
    // 查询课程的数量
    async getCountByUser() {
      try {
        let res = await getCountByUser()
        this.$set(this.tabList[0], 'number', res.data.count)
      } catch (error) {}
    },
    onUpdate(value) {
      this.componentValue = value.componentValue
    }
  },
  components: {
    CourseAll,
    SelectTab,
    Order,
    Voucher,
    OrderDetail
  },
  computed: {
    ...mapGetters(['userType', 'equipmentType'])
  }
}
</script>

<style lang="scss" scoped>
.select-tab-box {
  position: -webkit-sticky;
  position: sticky;
  top: $robin-navbar-height-app;
  z-index: 1;
}
@media screen and (min-width: 768px) {
  .select-tab-box {
    // position: relative;
  }
}
</style>
