<template>
  <div class="order-all">
    <div class="content-card" v-for="item in orderList" :key="item.id" @click="redirectDetail(item)">
      <div class="content-card-top">
        <img :src="item.course.picture" alt="" class="order-img" v-if="item.pictureImgExists" />
        <img src="../../../../assets/placeholder.png" alt="" class="order-img" v-else />
        <div class="content-card-top-right">
          <div class="right-title">
            <div class="right-title-text">{{ item.course.courseName }}</div>
            <!--0待付款 1成功 2失败 3取消 4关闭 -->
            <div class="right-title-status">
              {{
                item.orderState === 1
                  ? '交易成功'
                  : item.orderState === 2
                  ? '交易失败'
                  : item.orderState === 3
                  ? '已取消'
                  : item.orderState === 4
                  ? '已关闭'
                  : item.orderState === 0
                  ? '待付款'
                  : '暂无状态'
              }}
            </div>
          </div>
          <div class="right-money">
            <div class="right-money-price">¥{{ item.orderItemPrice }}</div>
            <div class="right-money-number">x{{ item.orderItemQuantity }}</div>
          </div>
          <div class="right-info">
            <span class="author" v-if="item.course.lecturerName">作者：{{ item.course.lecturerName || '暂无' }}</span>
            <span class="author" v-else>作者：暂无</span>
            <span class="chapter">{{ item.course.lessonCount }}节课</span>
          </div>
        </div>
      </div>
      <div class="content-card-bottom">
        <!-- <div class="bottom-delete">删除订单</div> -->
        <!-- <div class="bottom-detail" @click="redirectDetail">订单详情</div> -->
        <div class="bottom-pay" v-if="item.orderState === 0" @click.stop="goPay(item)">去支付</div>
        <!-- <div class="bottom-again">再次购买</div> -->
      </div>
    </div>
    <!-- pc样式 -->
    <div class="content-web" v-if="(!loadingShow && equipmentType == 'WEB') || equipmentType == 'APP'">
      <div class="content-web-title">
        <div>课程</div>
        <div>购买方式</div>
        <div>单价(元)</div>
        <div>数量</div>
        <div>实际支付</div>
        <div>状态</div>
        <div>操作</div>
      </div>
      <div class="content-web-card" v-for="item in orderList" :key="item.id">
        <div class="content-web-card-left">
          <img :src="item.course.picture" alt="" class="order-img" v-if="item.pictureImgExists" />
          <img src="../../../../assets/placeholder.png" alt="" class="order-img" v-else />
          <div class="content-web-card-left-right">
            <span class="right-title">
              {{ item.course.courseName }}
            </span>
            <div class="right-company">
              <span class="author" v-if="item.course.lecturerName">作者：{{ item.course.lecturerName || '暂无' }}</span>
              <span class="author" style="margin-left: 34px">共{{ item.course.lessonCount }}节课</span>
              <!-- <span class="author">有效期365天</span> -->
            </div>
          </div>
        </div>
        <div class="pay-method">{{ item.orderType === 0 ? '个人购买' : '企业购买' }}</div>
        <div class="pay-price">￥{{ item.orderItemPrice || '' }}</div>
        <div class="pay-number">{{ item.orderItemQuantity }}</div>
        <div class="pay-total-price">￥{{ item.payPrice }}</div>
        <div class="pay-state">
          {{
            item.orderState === 1
              ? '交易成功'
              : item.orderState === 2
              ? '交易失败'
              : item.orderState === 3
              ? '已取消'
              : item.orderState === 4
              ? '已关闭'
              : item.orderState === 0
              ? '待付款'
              : '暂无状态'
          }}
        </div>
        <div class="pay-operation">
          <div class="pay-button" v-if="item.orderState === 0" @click.stop="goPayWeb(item)">去支付</div>
          <div class="pay-detail" v-else @click="redirectDetailWeb(item)">订单详情</div>
        </div>
      </div>
    </div>

    <NoData v-if="noDataShow">
      <template slot="text">暂无数据</template>
    </NoData>
    <ToLoading :loadingShow="loadingShow" :noLoadingTextShow="noLoadingTextShow"></ToLoading>

    <div class="pagination" :class="total > 10 ? '' : 'pagination-pad'">
      <pagination v-show="total > 10" :total="total" :page.sync="pageNum" :limit.sync="pageSize" @pagination="getAppList" />
    </div>
  </div>
</template>

<script>
import { getAppList } from '@/api/personalCenter.js'
import { mapGetters } from 'vuex'
import { checkImgExists } from '@/utils/common'
export default {
  name: 'OrderAll',
  props: {
    selectTabValue: {
      type: String,
      default: 'CourseAll'
    }
  },
  data() {
    return {
      pageNum: 1,
      pageSize: 10,
      courseListLength: 10, //和pageSize初始化保持一致
      orderList: [],
      requestCompleted: true, // 请求是否完成
      // ToLoadingShow: false, // 是否显示loading组件
      loadingShow: false, // 是否显示loading
      noLoadingTextShow: false, // 是否显示'到底了'
      noDataShow: false,
      total: 0
    }
  },
  watch: {
    equipmentType: {
      handler: function (newVal, oldVal) {
        // this.ToLoadingShow = newVal == 'APP' ? true : false
        if ((newVal == 'WEB' && oldVal == 'APP') || (newVal == 'APP' && oldVal == 'WEB')) {
          location.reload()
        }
        // 避免pc和手机相互切换时数据页面乱的问题
        this.pageNum = 1
      },
      immediate: true
    },
    selectTabValue: {
      handler: function (newVal, oldVal) {
        document.documentElement.scrollTop = 0
        // 防止下拉Tab切换数据错乱
        this.pageNum = 1
      },
      immediate: true
    }
  },
  created() {
    this.getAppList()
    if (this.equipmentType == 'APP') {
      // 分页
      window.addEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed: function () {
    if (this.equipmentType == 'APP') {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    // 获取所有订单列表
    async getAppList() {
      this.noDataShow = false
      this.loadingShow = true
      try {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        let res = await getAppList(params)
        if (res.data?.list.length > 0) {
          res.data.list.forEach(async item => {
            item.pictureImgExists = true
          })
        }
        this.courseListLength = res.data?.list?.length || 0
        this.noLoadingTextShow = this.courseListLength < this.pageSize ? true : false
        if (this.equipmentType == 'APP') {
          this.orderList = this.orderList.concat(res.data?.list || [])
        } else {
          this.orderList = res.data?.list || []
          this.noLoadingTextShow = false
        }
        this.orderList.forEach(async item => {
          item.pictureImgExists = await checkImgExists(item.course.picture)
        })
        this.requestCompleted = true
        this.loadingShow = false
        if (this.orderList.length === 0) {
          // this.ToLoadingShow = false
          this.noDataShow = true
          this.loadingShow = false
          this.noLoadingTextShow = false
        }
        this.total = res.data.total
      } catch (error) {
        this.requestCompleted = true
        this.loadingShow = false
        this.noDataShow = true
      }
    },
    handleScroll() {
      if (this.$route.path !== '/personalCenter' && this.selectTabValue !== 'Order') return // 解决再别的页面触发
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0) {
        if (scrollHeight - (scrollTop + windowHeight) < 1) {
          if (this.courseListLength < this.pageSize) {
            return
          }
          if (this.equipmentType == 'APP') {
            if (this.requestCompleted) {
              this.requestCompleted = false
              // 上次请求完成后，再调用下次请求
              setTimeout(() => {
                this.pageNum = this.pageNum + 1
                this.getAppList()
              }, 500)
            }
          } else {
            // this.loadingShow = false
          }
        }
      }
    },
    // 支付按钮
    goPay(value) {
      this.$router.push({
        path: '/confirmOrder',
        query: {
          id: value.orderItemId,
          orderId: value.id,
          numberValue: value.orderItemQuantity,
          page: 'personalCenter',
          orderNo: value.orderNo
        }
      })
    },
    redirectDetail(value) {
      this.$router.push({
        path: '/orderDetail',
        query: { orderId: value.id }
      })
    },
    redirectDetailWeb(value) {
      this.$router.push({
        path: '/personalCenter',
        query: { orderId: value.id, page: 'personalCenter' }
      })
      this.$emit('value-updated', {
        componentValue: 'OrderDetail'
      })
    },
    goPayWeb(value) {
      this.$router.push({
        path: '/personalCenter',
        query: { orderId: value.id, page: 'personalCenter' }
      })
      this.$emit('value-updated', {
        componentValue: 'OrderDetail'
      })
    }
  },
  computed: {
    ...mapGetters(['equipmentType', 'userType'])
  }
}
</script>

<style lang="scss" scoped>
.order-all {
  box-sizing: border-box;
  background: #f9f9f9;
  padding: 4%;
  overflow-y: auto;
  padding-bottom: calc(41px);
  background: #fff;
  margin-top: 9px;
  .content-card {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    margin: auto;
    background: #ffffff;
    padding: 8px 11px 5px 8px;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    margin-bottom: 10px;
    .content-card-top {
      display: flex;
      justify-content: space-between;
      .order-img {
        width: 120px;
        height: 78px;
        border-radius: 8px;
      }
      &-right {
        width: calc(100% - 120px - 12px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .right-title {
          font-size: 14px;
          font-weight: bold;
          color: #373c41;
          display: flex;
          justify-content: space-around;
          &-text {
            width: calc(100% - 60px);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          &-status {
            width: 60px;
            text-align: right;
            font-size: 14px;
            font-weight: bold;
            color: #ff5500;
          }
        }
        .right-money {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .right-money-price,
          .right-money-number {
            font-size: 14px;
            font-weight: bold;
            color: #8e8e93;
          }
        }
        .right-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .author {
            font-size: 12px;
            font-weight: 400;
            color: #8e8e93;
          }
          .chapter {
            font-size: 12px;
            font-weight: 400;
            color: #8e8e93;
          }
        }
      }
    }
    &-bottom {
      height: calc(100% - 78px);
      display: flex;
      align-items: center;
      // justify-content: space-around;
      justify-content: flex-end;
      .bottom-delete,
      .bottom-detail {
        height: 28px;
        padding: 0 24px;
        line-height: 28px;
        text-align: center;
        border-radius: 14px;
        border: 1px solid #8e8e93;
        font-size: 12px;
        font-weight: 400;
        color: #373c41;
      }
      .bottom-pay,
      .bottom-again {
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        // line-height: 28px;
        text-align: center;
        border-radius: 14px;
        border: 1px solid #ff5500;
        font-size: 12px;
        font-weight: 400;
        color: #ff5500;
        margin: 15px;
      }
    }
  }
  .content-web {
    display: none;
  }
  .pagination {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .order-all {
    max-width: 1200px;
    margin: auto;
    padding: 2%;
    .content-card {
      display: none;
    }
    .content-web {
      display: block;
      &-title {
        height: 34px;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        color: #8e8e93;
        padding: 0 32px;
        margin-top: 19px;
        div:nth-child(1) {
          width: 40%;
        }
        div:nth-child(2) {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(3) {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(4) {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(5) {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(6) {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(7) {
          width: calc(60% / 6);
          text-align: center;
        }
      }
      &-card {
        padding: 0px 32px 0;
        width: 100%;
        height: 128px;
        border-bottom: 0.5px solid rgba(218, 218, 218, 0.5);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-left {
          width: 40%;
          display: flex;
          justify-content: space-between;
          .order-img {
            width: 126px;
            height: 80px;
            border-radius: 7px;
          }
          &-right {
            width: calc(100% - 126px - 12px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .right-title {
              font-size: 14px;
              font-weight: bold;
              color: #373c41;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .right-company {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .author {
                font-size: 12px;
                font-weight: 400;
                color: #8e8e93;
              }
            }
          }
        }
        .pay-method {
          width: calc(60% / 6);
          text-align: center;
        }
        .pay-price {
          width: calc(60% / 6);
          text-align: center;
        }
        .pay-number {
          width: calc(60% / 6);
          text-align: center;
        }
        .pay-total-price {
          width: calc(60% / 6);
          text-align: center;
        }
        .pay-state {
          width: calc(60% / 6);
          text-align: center;
        }
        div:nth-child(5) {
          font-weight: bold;
          color: #d30000;
        }
        .pay-operation {
          width: calc(60% / 6);
          display: flex;
          justify-content: center;
          .pay-button {
            width: 85px;
            height: 34px;
            line-height: 34px;
            text-align: center;
            background: #ff5500;
            border-radius: 61px;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
          .pay-detail {
            width: 85px;
            height: 34px;
            line-height: 34px;
            border-radius: 61px;
            border: 1px solid #ff5500;
            font-size: 14px;
            color: #ff5500;
            text-align: center;
            cursor: pointer;
          }
        }
      }
    }
    .pagination {
      width: 100%;
      text-align: center;
      display: block;
    }
    .pagination-pad {
      padding-bottom: 96px;
    }
  }
}
</style>
