<template>
  <div class="course-company" :class="total > 20 ? '' : 'course-company-pad'">
    <div class="course-item-box tail-courseList" v-if="(!loadingShow && equipmentType == 'WEB') || equipmentType == 'APP'">
      <div class="course-item tail-courseList-item" v-for="item in courseList" :key="item.id" @click="redirectCourseDetail(item.courseId)">
        <div class="item-box">
          <div class="item-img">
            <img :src="item.course.picture" alt="" v-if="item.pictureImgExists" />
            <img src="../../../../assets/placeholder.png" alt="" v-else />
            <div class="item-valid-time">有效期至{{ item.expireTime.split(' ')[0] }}</div>
          </div>
          <div class="item-info">
            <div class="item-title">{{ item.course.courseName }}</div>
            <div class="item-bottom">
              <p class="item-author" v-if="item.course.lecturerName">作者：{{ item.course.lecturerName || '暂无' }}</p>
              <p class="item-author" v-else>作者：暂无</p>
              <p class="item-class">{{ item.course.lessonCount }}节课</p>
            </div>
          </div>
        </div>
        <div class="item-allot">
          <div class="item-time">可分配:{{ item.amount }}</div>
          <div class="item-btn" @click.stop="distributedLearning">分配学习</div>
        </div>
      </div>
    </div>
    <NoData v-if="noDataShow">
      <template slot="text">暂无数据</template>
    </NoData>
    <ToLoading :loadingShow="loadingShow" :noLoadingTextShow="noLoadingTextShow"></ToLoading>

    <div class="pagination">
      <pagination v-show="total > 20" :total="total" :page.sync="pageNum" :limit.sync="pageSize" @pagination="getCourseCompanyList" />
    </div>

    <AffirmDialog :affirmDialogShow="affirmDialogShow" @submitDialog="submitDialog" @cancelDialog="cancelDialog">
      即将跳转到企业后台管理，建议您使用电脑浏览器访问
    </AffirmDialog>
  </div>
</template>
<script>
import { getCourseCompany } from '@/api/personalCenter'
import { mapGetters } from 'vuex'
import { getBackstageUrl } from '@/utils/config.js'
import { checkImgExists } from '@/utils/common'
export default {
  name: 'courseCompany',
  props: {
    selectTabValue: {
      type: String,
      default: 'CourseAll'
    }
  },
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 20,
      courseListLength: 20, //和pageSize初始化保持一致
      affirmDialogShow: false,
      courseList: [],
      requestCompleted: true, // 请求是否完成
      // ToLoadingShow: false, // 是否显示loading组件
      loadingShow: false, // 是否显示loading
      noLoadingTextShow: false, // 是否显示'到底了'
      noDataShow: false
    }
  },
  watch: {
    equipmentType: {
      handler: function (newVal, oldVal) {
        // this.ToLoadingShow = newVal == 'APP' ? true : false
        if ((newVal == 'WEB' && oldVal == 'APP') || (newVal == 'APP' && oldVal == 'WEB')) {
          location.reload()
        }
        // 避免pc和手机相互切换时数据页面乱的问题
        this.pageNum = 1
      },
      immediate: true
    },
    selectTabValue: {
      handler: function (newVal, oldVal) {
        document.documentElement.scrollTop = 0
        // 防止下拉Tab切换数据错乱
        this.pageNum = 1
      },
      immediate: true
    }
  },
  created() {
    this.getCourseCompanyList()
    if (this.equipmentType == 'APP') {
      // 分页
      window.addEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed: function () {
    if (this.equipmentType == 'APP') {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    // 获取企业课程列表
    async getCourseCompanyList() {
      this.noDataShow = false
      this.loadingShow = true
      try {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        let res = await getCourseCompany(params)
        if (res.data?.list.length > 0) {
          res.data.list.forEach(async item => {
            item.pictureImgExists = true
          })
        }
        this.courseListLength = res.data?.list?.length || 0
        this.noLoadingTextShow = this.courseListLength < this.pageSize ? true : false
        if (this.equipmentType == 'APP') {
          this.courseList = this.courseList.concat(res.data?.list || [])
        } else {
          this.courseList = res.data?.list || []
          this.noLoadingTextShow = false
        }
        this.courseList.forEach(async item => {
          item.pictureImgExists = await checkImgExists(item.course.picture)
        })
        this.requestCompleted = true
        this.loadingShow = false
        if (this.courseList.length === 0) {
          this.noDataShow = true
          this.loadingShow = false
          this.noLoadingTextShow = false
        }
        this.total = res.data.total
      } catch (error) {
        this.requestCompleted = true
        this.loadingShow = false
        this.noDataShow = true
      }
    },
    handleScroll() {
      if (this.$route.path !== '/personalCenter' && this.selectTabValue !== 'CourseAll') return // 解决再别的页面触发
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0) {
        if (scrollHeight - (scrollTop + windowHeight) < 1) {
          if (this.courseListLength < this.pageSize) {
            return
          }
          if (this.equipmentType == 'APP') {
            if (this.requestCompleted) {
              this.requestCompleted = false
              // 上次请求完成后，再调用下次请求
              setTimeout(() => {
                this.pageNum = this.pageNum + 1
                this.getCourseCompanyList()
              }, 500)
            }
          } else {
            // this.loadingShow = false
          }
        }
      }
    },
    // 分配学习
    distributedLearning() {
      this.affirmDialogShow = true
    },
    submitDialog() {
      this.affirmDialogShow = false
      // window.open('https://test.huizhiyunping.com/index', '_blank')
      window.open(getBackstageUrl() + '/', '_blank')
    },
    redirectCourseDetail(courseId) {
      this.$router.replace({
        path: '/courseDetails',
        query: { id: courseId, page: 'courseCompany' }
      })
    },
    cancelDialog() {
      this.affirmDialogShow = false
    }
  },
  computed: {
    ...mapGetters(['equipmentType'])
  }
}
</script>
<style lang="scss" scoped>
.tail-courseList {
  @apply flex flex-wrap items-center;
}
.tail-courseList-item {
  @apply w-full;
}
.course-company {
  padding: 15px;
  background: #ffffff;
  .course-item-box {
    .course-item {
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.11);
      padding: 8px;
      border-radius: 6px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
      .item-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;
        .item-img {
          width: 120px;
          height: 78px;
          border-radius: 6px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
          }
          .item-valid-time {
            display: none;
          }
        }
        .item-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: calc(100% - 132px);
          .item-title {
            font-size: 14px;
            font-weight: bold;
            color: $robin-title-color;
            margin-bottom: 16px;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
          }
        }
        .item-bottom {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          .item-author {
            color: #8e8e93;
          }
          .item-class {
            color: $robin-title-color;
          }
        }
      }
      .item-allot {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-time {
          width: 120px;
          text-align: center;
          font-weight: 400;
          color: $robin-essential-color;
        }
        .item-btn {
          width: calc(100% - 132px);
          height: 30px;
          line-height: 30px;
          text-align: center;
          background: #fdf1e2;
          border-radius: 15px;
          font-size: 14px;
          font-weight: 400;
          color: $robin-essential-color;
        }
      }
    }
  }
  .pagination {
    display: none;
  }
}

@media (min-width: 768px) {
  .course-company-pad {
    padding: 19px 30px 117px !important;
  }
  .course-company {
    padding: 19px 30px 37px;
    .course-item-box {
      .course-item {
        width: calc((100% - 75px) / 4);
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.11);
        margin-bottom: 25px;
        margin-right: 25px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 25px;
        }
        &:nth-child(4n) {
          margin-right: 0;
        }
        .item-box {
          flex-direction: column;
          .item-img {
            width: 100%;
            height: 131px;
            position: relative;
            margin-bottom: 13px;
            .item-valid-time {
              display: block;
              position: absolute;
              bottom: 8px;
              left: 13px;
              font-size: 14px;
              font-weight: 400;
              color: #ffffff;
            }
          }
          .item-info {
            width: 100%;
            .item-title {
              font-size: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              line-height: 25px;
              max-height: 25px;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              margin-bottom: 13px;
            }
            .item-bottom {
              font-size: 16px;
            }
          }
        }
        .item-allot {
          .item-time {
            width: auto;
          }
          .item-btn {
            width: 100px;
          }
        }
      }
    }
    .pagination {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
