<template>
  <div class="content-tab">
    <div
      class="tab-item"
      v-for="item in contentTabList"
      :key="item.value"
      :class="contentTabAct === item.value ? 'tab-item-act' : ''"
      @click="changeContentTab(item)"
    >
      {{ item.label }}
      <span>{{ item.num }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContentTab',
  props: {
    contentTabList: {
      type: Array,
      default: () => [
        {
          label: '个人购买',
          value: 'user'
        },
        {
          label: '企业分配',
          value: 'company'
        }
      ]
    },
    contentTabAct: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    changeContentTab(item) {
      this.$emit('changeContentTab', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.content-tab {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 45px;
  background: #fff;
  .tab-item {
    font-weight: 400;
    font-size: 14px;
    color: $robin-title-color;
  }
  .tab-item-act {
    font-weight: bold;
    color: $robin-essential-color;
  }
}
</style>
