import request from '@/utils/request'
// 获取兑换券数量
export function getCountOfUser() {
  return request({
    url: `train/state/countOfUser`,
    method: 'GET'
  })
}

// 获取已购课程数量
export function getCountByUser() {
  return request({
    url: `train/courseOwn/countByUser`,
    method: 'GET'
  })
}
// 获取企业课程列表
export function getCourseCompany(params) {
  return request({
    url: '/train/courseOwn/listByCompany',
    method: 'get',
    params
  })
}

// 获取我的订单
export function getAppList(params) {
  return request({
    url: `shopping/order/appList`,
    method: 'GET',
    params
  })
}

// 获取APP用户---个人购买课程列表
export function getListByUser(params) {
  return request({
    url: `train/courseOwn/listByUser`,
    method: 'GET',
    params
  })
}

// 获取订单详情
export function getOrdersDeatil(id) {
  return request({
    url: `shopping/order/${id}`,
    method: 'GET'
  })
}

// 取消订单
export function getOrdersCancel(id) {
  return request({
    url: `shopping/orderPay/cancel/${id}`,
    method: 'POST'
  })
}
