<template>
  <div class="course-app">
    <div class="course-app-tap">
      <content-tab :contentTabList="contentTabList" :contentTabAct="contentTabAct" @changeContentTab="changeContentTab"></content-tab>
    </div>
    <!-- 个人购买 -->
    <div v-if="contentTabAct == '0'" class="course-box my-self tail-courseList">
      <div class="course-item tail-courseList-item" v-for="(item, index) in courseList" :key="index" @click="redirectDetail(item)">
        <div class="course-img">
          <img :src="item.course.courseIntroduce" alt="" />
        </div>
        <div class="course-info">
          <div class="course-title">{{ item.course.courseName || '暂无' }}</div>
          <div class="course-bottom">
            <p class="course-author">作者：{{ item.course.lecturerName }}</p>
            <p class="course-class">{{ item.course.lessonCount }}节课</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 企业分配 -->
    <div v-else class="course-box my-self tail-courseList">
      <div class="course-item tail-courseList-item" v-for="(item, index) in courseList" :key="index" @click="redirectDetail(item)">
        <div class="course-img">
          <img :src="item.course.courseIntroduce" alt="" />
        </div>
        <div class="course-info">
          <div class="course-title">{{ item.course.courseName || '暂无' }}</div>
          <div class="course-bottom">
            <p class="course-author" v-if="item.course.lecturerName">作者：{{ item.course.lecturerName }}</p>
            <p class="course-author" v-else>作者：暂无</p>
            <p class="course-class">{{ item.course.lessonCount }}节课</p>
          </div>
        </div>
      </div>
    </div>
    <NoData v-if="noDataShow">
      <template slot="text">暂无数据</template>
    </NoData>
    <ToLoading v-show="ToLoadingShow" :loadingShow="loadingShow" :noLoadingTextShow="noLoadingTextShow"></ToLoading>
    <!-- 去学习按钮 -->
    <div class="bottom-button">
      <div class="go-app" @click="redirectRobin">去企云巅App学习</div>
    </div>
  </div>
</template>
<script>
import { setToken } from '@/utils/auth'
import ContentTab from '../../components/ContentTab'
import { getListByUser } from '@/api/personalCenter.js'
import { mapGetters } from 'vuex'
export default {
  name: 'courseApp',
  props: {
    selectTabValue: {
      type: String,
      default: 'CourseAll'
    }
  },
  components: {
    ContentTab
  },
  watch: {
    equipmentType: {
      handler: function (newVal, oldVal) {
        this.ToLoadingShow = newVal == 'APP' ? true : false
        // 避免pc和手机相互切换时数据页面乱的问题
        this.pageNum = 1
      },
      immediate: true
    },
    selectTabValue: {
      handler: function (newVal, oldVal) {
        document.documentElement.scrollTop = 0
        // 防止下拉Tab切换数据错乱
        this.pageNum = 1
      },
      immediate: true
    }
  },
  data() {
    return {
      contentTabList: [
        {
          label: '个人购买',
          value: '0'
        },
        {
          label: '企业分配',
          value: '2'
        }
      ],
      contentTabAct: '0', //source  :  空（全部）   0（购买）  2（分配）
      pageNum: 1,
      pageSize: 10,
      courseListLength: 10, //和pageSize初始化保持一致
      affirmDialogShow: false,
      courseList: [],
      requestCompleted: true, // 请求是否完成
      ToLoadingShow: false, // 是否显示loading组件
      loadingShow: false, // 是否显示loading
      noLoadingTextShow: false, // 是否显示'到底了'
      noDataShow: false
    }
  },
  created() {
    this.getListByUser()
    // setToken(
    //   'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjY0YjE0ZDU0LTE4ZDEtNDZjNi1iNWRiLTRkNmEwMmFjNzU5ZiJ9.cvtx8iNV8HXOcFjGnWjTNGfZ33POc1wVedrY2Eh8NQWjSwwUKBzQ9U0RV7Hy21flvB7AkWXG-PvMwAtxqsRFWg'
    // )
    if (this.equipmentType == 'APP') {
      // 分页
      window.addEventListener('scroll', this.handleScroll, true)
    }
  },
  destroyed: function () {
    if (this.equipmentType == 'APP') {
      window.removeEventListener('scroll', this.handleScroll)
    }
  },
  methods: {
    redirectRobin() {
      window.location.href = 'https://test.huizhiyunping.com//web/#/download?type=screen'
    },
    changeContentTab(item) {
      this.courseList = []
      this.pageNum = 1
      this.contentTabAct = item.value
      this.getListByUser()
    },
    async getListByUser() {
      this.noDataShow = false
      this.ToLoadingShow = true
      this.loadingShow = true
      try {
        let params = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          source: this.contentTabAct
        }
        let res = await getListByUser(params)
        this.courseListLength = res.data?.list?.length || 0
        this.noLoadingTextShow = this.courseListLength < this.pageSize ? true : false
        if (this.equipmentType == 'APP') {
          this.courseList = this.courseList.concat(res.data.list)
        } else {
          this.courseList = res.data?.list || []
        }
        this.requestCompleted = true
        this.loadingShow = false
        if (this.courseList.length === 0) {
          this.noDataShow = true
        }
      } catch (error) {
        this.requestCompleted = true
        this.loadingShow = false
        this.noDataShow = true
      }
    },
    handleScroll() {
      if (this.$route.path !== '/personalCenter' && this.selectTabValue !== 'CourseAll') return // 解决再别的页面触发
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const windowHeight = window.innerHeight
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 滚动条到底部的条件
      if (scrollTop > 0) {
        if (scrollHeight - (scrollTop + windowHeight) < 1) {
          if (this.courseListLength < this.pageSize) {
            return
          }
          if (this.equipmentType == 'APP') {
            if (this.requestCompleted) {
              this.requestCompleted = false
              // 上次请求完成后，再调用下次请求
              setTimeout(() => {
                this.pageNum = this.pageNum + 1
                this.getListByUser()
              }, 500)
            }
          } else {
            // this.loadingShow = false
          }
        }
      }
    },
    redirectDetail(value) {
      this.$router.push({
        path: '/courseDetails',
        query: { id: value.course.id, page: 'courseApp' }
      })
    }
  },
  computed: {
    ...mapGetters(['equipmentType'])
  }
}
</script>
<style lang="scss" scoped>
.tail-courseList {
  @apply flex flex-wrap items-center;
}
.tail-courseList-item {
  @apply md:w-1/4 w-full;
}
.course-app {
  background: #ffffff;
  padding: 0px 15px 15px;
  // .course-app-tap {
  //   position: -webkit-sticky;
  //   position: sticky;
  //   top: calc($robin-navbar-height-app + 40px);
  //   z-index: 1;
  // }
  .course-box {
    margin-bottom: 20px;
    .course-item {
      padding: 8px;
      background: #ffffff;
      box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.11);
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      .course-img {
        width: 120px;
        height: 78px;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .course-info {
        width: calc(100% - 132px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .course-title {
          font-size: 14px;
          font-weight: bold;
          color: $robin-title-color;
          margin-bottom: 16px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
        }
        .course-bottom {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          font-weight: 400;
          .course-author {
            color: #8e8e93;
          }
          .course-class {
            color: $robin-title-color;
          }
        }
      }
    }
  }
  .bottom-button {
    height: 40px;
    margin-top: 50px;
    .go-app {
      position: fixed;
      left: 50%;
      bottom: 14px;
      transform: translate(-50%, 0);
      width: 170px;
      height: 40px;
      line-height: 40px;
      background: $robin-essential-color;
      border-radius: 20px;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      color: #ffffff;
    }
  }
}
</style>
