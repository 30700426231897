<template>
  <div class="select-tab">
    <div
      class="tab-item"
      v-for="item in tabList"
      :key="item.value"
      :class="selectTabValue === item.value ? 'tab-item-active' : ''"
      @click="changeTab(item)"
    >
      {{ item.label }}
      <span>{{ item.number }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectTab',
  props: {
    tabList: {
      type: Array,
      default: () => [
        {
          label: 'tab1',
          value: 'tab1',
          number: 12
        },
        {
          label: 'tab2',
          value: 'tab2',
          number: 12
        },
        {
          label: 'tab3',
          value: 'tab3'
        }
      ]
    },
    selectTabValue: {
      type: String,
      default: 'CourseAll'
    }
  },

  data() {
    return {
      changeTab(value) {
        this.$emit('changeTab', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-tab {
  width: 100%;
  height: 46px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 12px;
  .tab-item {
    height: 46px;
    line-height: 46px;
    font-size: 14px;
    font-weight: 400;
    color: #373c41;
    position: relative;
    cursor: pointer;
    // &::before {
    //   position: absolute;
    //   right: -30%;
    //   top: 50%;
    //   transform: translate(0%, -50%);
    //   display: block;
    //   content: '';
    //   width: 1px;
    //   height: 16px;
    //   background: rgba(151, 151, 151, 0.3);
    // }
  }
  .tab-item:last-child {
    &::before {
      width: 0px;
      height: 0px;
    }
  }
  .tab-item-active {
    position: relative;
    font-size: 14px;
    font-weight: bold;
    color: #373c41;
    &::after {
      position: absolute;
      left: 50%;
      bottom: 0%;
      transform: translate(-50%, 0%);
      display: block;
      content: '';
      width: 29px;
      height: 2px;
      background: $robin-essential-color;
    }
  }
}
@media screen and (min-width: 768px) {
  .select-tab {
    max-width: 1200px;
    margin: 12px auto;
    background: #f8f8f8;
    height: 68px;
    justify-content: flex-start;
    .tab-item {
      width: 15%;
      height: 68px;
      line-height: 68px;
      font-size: 16px;
      font-weight: 400;
      color: #373c41;
      display: flex;
      justify-content: center;
    }
    .tab-item-active {
      font-size: 16px;
      font-weight: bold;
      color: #373c41;
    }
  }
}
</style>
