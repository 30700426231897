<template>
  <div class="course-all">
    <!-- 企业用户(管理员) -->
    <div v-if="isCompanyUser">
      <course-company></course-company>
    </div>

    <!-- 个人用户 -->
    <div v-else>
      <course-app></course-app>
    </div>
  </div>
</template>
<script>
import CourseCompany from './courseCompany'
import CourseApp from './courseApp'
import { mapGetters } from 'vuex'
export default {
  name: 'courseAll',
  components: {
    CourseCompany,
    CourseApp
  },
  computed: {
    ...mapGetters(['userType']),
    isCompanyUser() {
      return this.userType == 'companyUser' ? true : false
    }
  }
}
</script>
<style lang="scss" scoped>
.course-all {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .course-all {
    max-width: 1200px;
    margin: 10px auto 0;
  }
}
</style>
