<template>
  <div class="personal-center">
    <PersonalHeader></PersonalHeader>
    <PersonalContent></PersonalContent>
  </div>
</template>

<script>
import PersonalHeader from './personalHeader'
import PersonalContent from './personalContent'
export default {
  name: 'PersonalCenter',
  components: {
    PersonalHeader,
    PersonalContent
  }
}
</script>

<style lang="scss" scoped>
.personal-center {
  background: #f9f9f9;
  margin-top: $robin-navbar-height-app;
  border-top: 1px solid transparent;
}
@media screen and (min-width: 768px) {
  .personal-center {
    margin-top: $robin-navbar-height-web;
  }
}
</style>
