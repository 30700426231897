<template>
  <div class="order-detail">
    <header>
      <div class="header" @click="onBackPage">
        <img src="../../../../assets/back-icon.png" alt="" srcset="" class="back-icon" />
        <span>
          {{
            orderDetail.orderState === 0
              ? '订单待付款'
              : orderDetail.orderState === 1
              ? '订单交易成功'
              : orderDetail.orderState === 2
              ? '订单交易失败'
              : orderDetail.orderState === 3
              ? '订单已取消'
              : orderDetail.orderState === 4
              ? '订单已关闭'
              : '订单详情'
          }}
        </span>
      </div>
    </header>
    <div class="order-detail-content">
      <!-- app样式 -->
      <div class="content-card">
        <div class="content-card-top">
          <img :src="orderDetail.course.picture" alt="图片异常" class="order-img" v-if="pictureImgExists" />
          <img src="../../../../assets/placeholder.png" alt="" class="order-img" v-else />
          <div class="content-card-top-right">
            <div class="right-title">{{ orderDetail.course.courseName || '暂无' }}</div>
            <div class="right-chapter">共{{ orderDetail.course.lessonCount }}节课</div>
            <div class="right-company">
              <div>
                <!-- orderItemPrice商品单价 -->
                <span class="right-money">¥{{ orderDetail.orderItemPrice }}</span>
                <span class="right-number">x{{ orderDetail.orderItemQuantity }}</span>
              </div>
              <span class="company">{{ orderDetail.orderType === 0 ? '个人购买' : '企业购买' }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- pc样式 -->
      <div class="content-web">
        <div class="content-web-card">
          <div class="content-web-card-left">
            <img :src="orderDetail.course.picture" alt="图片异常" class="order-img" v-if="pictureImgExists" />
            <img src="../../../../assets/placeholder.png" alt="" class="order-img" v-else />
            <div class="content-web-card-left-right">
              <span class="right-title">
                {{ orderDetail.course.courseName || '暂无' }}
              </span>
              <div class="right-company">
                <span class="author" v-if="orderDetail.course.lecturerName">作者：{{ orderDetail.course.lecturerName || '暂无' }}</span>
                <span class="author" style="margin-left: 34px">共{{ orderDetail.course.lessonCount }}节课</span>
                <!-- <span class="author">有效期365天</span> -->
              </div>
            </div>
          </div>
          <div class="pay-method">
            <div class="pay-method-orderType">{{ orderDetail.orderType === 0 ? '个人购买' : '企业购买' }}</div>
            <div class="pay-method-price">
              <span>￥{{ orderDetail.orderItemPrice || '' }}</span>
              <span>x{{ orderDetail.orderItemQuantity }}</span>
            </div>
          </div>
          <div class="pay-state">
            {{
              orderDetail.orderState === 0
                ? '待付款'
                : orderDetail.orderState === 1
                ? '交易成功'
                : orderDetail.orderState === 2
                ? '交易失败'
                : orderDetail.orderState === 3
                ? '已取消'
                : orderDetail.orderState === 4
                ? '已关闭'
                : '订单详情'
            }}
          </div>
        </div>
      </div>
      <div class="pay-detail">
        <div class="money-detail">
          <span>课程总额</span>
          <!-- payPrice 总价 -->
          <span>¥{{ orderDetail.payPrice }}</span>
        </div>
        <div class="total-money">
          <span>共{{ orderDetail.orderItemQuantity }}件</span>
          <span>实付:</span>
          <span>¥{{ orderDetail.payPrice }}</span>
        </div>
      </div>
      <div class="order-info">
        <div class="order-info-header">订单信息</div>
        <div class="order-info-number">
          <span>订单号码</span>
          <span>{{ orderDetail.orderNo }}</span>
        </div>
        <div class="order-info-time">
          <span>下单时间</span>
          <span>{{ orderDetail.createTime }}</span>
        </div>
        <div class="order-info-pay">
          <span>支付方式</span>
          <span>在线支付</span>
        </div>
      </div>
    </div>
    <div class="confirm-order-footer" v-if="orderDetail.orderState === 0">
      <div class="pay-submit">
        <button class="pay-submit-cancle" v-preventReClick="2500" @click="cancleOrder">取消订单</button>
        <button class="pay-submit-button" type="button" v-preventReClick="2500" @click="goPay">去支付</button>
      </div>
    </div>
    <AffirmDialog :affirmDialogShow="affirmDialogShow" @submitDialog="submitDialog" @cancelDialog="cancelDialog">您是否确定取消订购单?</AffirmDialog>
    <alert-msg :time="3000" :visibleMsg.sync="visibleMsg">{{ errMsg }}</alert-msg>
  </div>
</template>

<script>
import { getOrdersDeatil, getOrdersCancel } from '@/api/personalCenter.js'
import { mapGetters, mapActions } from 'vuex'
import { getUserType } from '@/utils/user.js'
import { checkImgExists } from '@/utils/common'
export default {
  name: 'OrderDetail',
  data() {
    return {
      orderId: this.$route.query.orderId,
      // headerTitle: '订单已完成',
      orderDetail: {
        course: {}
      },
      affirmDialogShow: false,
      errMsg: '',
      visibleMsg: false,
      page: '',
      pictureImgExists: true
    }
  },
  computed: {
    ...mapGetters(['userType'])
  },
  created() {
    this.getCookieInfo()
    this.getOrdersDeatil()
    this.page = this.$route.query?.page
  },
  methods: {
    ...mapActions({
      set_userType: 'SET_USERTYPE'
    }),
    async getOrdersDeatil() {
      let res = await getOrdersDeatil(this.orderId)
      this.orderDetail = res.data
      this.pictureImgExists = await checkImgExists(this.orderDetail.course.picture)
    },
    // 取消订单
    async getOrdersCancel() {
      try {
        let res = await getOrdersCancel(this.orderId)
        this.visibleMsg = true
        this.errMsg = res.msg
        this.getOrdersDeatil()
      } catch (error) {}
    },
    // 去支付
    goPay() {
      this.$router.push({
        path: '/confirmOrder',
        query: {
          id: this.orderDetail.course.id,
          numberValue: this.orderDetail.orderItemQuantity, //购买的数量
          page: 'orderDetail',
          orderNo: this.orderDetail.orderNo,
          orderId: this.orderId
        }
      })
    },
    cancleOrder() {
      this.affirmDialogShow = true
    },
    onBackPage() {
      switch (this.page) {
        case 'personalCenter':
          // 从个人中心-我的订单-列表中跳转过来
          this.$emit('value-updated', {
            componentValue: 'Order'
          })
          break
        default:
          this.$router.replace('/personalCenter')
      }
    },
    getCookieInfo() {
      if (getUserType()) {
        this.set_userType(getUserType())
      }
    },
    cancelDialog() {
      this.affirmDialogShow = false
    },
    submitDialog() {
      this.getOrdersCancel()
      this.affirmDialogShow = false
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  background: #f9f9f9;
  header {
    height: 40px;
  }
  .header {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-left: 23px;
    font-size: 16px;
    font-weight: bold;
    color: #2b2f36;
    text-align: center;
    z-index: 1;
    background: #fff;
    cursor: pointer;
    .back-icon {
      width: 9px;
      height: 17px;
      margin-right: 124px;
    }
  }
  &-content {
    box-sizing: border-box;
    background: #f9f9f9;
    padding: 4%;
    overflow-y: auto;
    padding-bottom: calc(41px);
    .content-card {
      box-sizing: border-box;
      width: 100%;
      height: 108px;
      margin: auto;
      background: #ffffff;
      border-radius: 7px;
      padding: 15px 9px 14px 8px;
      .content-card-top {
        display: flex;
        justify-content: space-between;
        .order-img {
          width: 120px;
          height: 78px;
          border-radius: 8px;
        }
        &-right {
          width: calc(100% - 120px - 12px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .right-title {
            font-size: 14px;
            font-weight: bold;
            color: #373c41;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .right-chapter {
            font-size: 12px;
            font-weight: 400;
            color: #373c41;
          }
          .right-company {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .right-money {
              font-size: 16px;
              font-weight: bold;
              color: #d30000;
            }
            .right-number {
              font-size: 16px;
              font-weight: 400;
              color: #373c41;
              margin-left: 5px;
            }
            .author {
              font-size: 12px;
              font-weight: 400;
              color: #8e8e93;
            }
            .company {
              background: #eeeeee;
              border-radius: 5px;
              font-size: 12px;
              font-weight: 400;
              color: #373c41;
              padding: 5px;
            }
          }
        }
      }
      &-bottom {
        height: calc(100% - 78px);
        display: flex;
        align-items: center;
        justify-content: space-around;
        .bottom-money {
          font-size: 16px;
          font-weight: bold;
          color: #d30000;
        }
        .bottom-chapter {
          font-size: 12px;
          font-weight: 400;
          color: #373c41;
        }
        .bottom-indate {
          font-size: 12px;
          font-weight: 400;
          color: #373c41;
        }
      }
    }
    .content-web {
      display: none;
    }
    .pay-detail {
      width: 100%;
      height: 136px;
      padding: 13px 13px 0 24px;
      background: #ffffff;
      border-radius: 7px;
      margin-top: 9px;
      .money-detail {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.2px solid rgb(151, 151, 151, 0.2);
        span:nth-child(1) {
          font-size: 14px;
          font-weight: bold;
          color: #2b2f36;
        }
        span:nth-child(2) {
          font-size: 18px;
          font-weight: bold;
          color: #d30000;
        }
      }
      .total-money {
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        span:nth-child(1) {
          font-size: 14px;
          font-weight: 400;
          color: #2b2f36;
          margin-left: 7px;
        }
        span:nth-child(2) {
          font-size: 14px;
          font-weight: bold;
          color: #2b2f36;
          margin-left: 7px;
        }
        span:nth-child(3) {
          font-size: 18px;
          font-weight: bold;
          color: #d30000;
          margin-left: 17px;
        }
      }
    }
    .order-info {
      height: 209px;
      margin-top: 9px;
      background: #ffffff;
      border-radius: 7px;
      padding: 24px 10px 20px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      &-header {
        font-size: 14px;
        font-weight: bold;
        color: #2b2f36;
      }
      &-number,
      &-time,
      &-indate,
      &-pay {
        height: calc(100% / 5);
        font-size: 14px;
        font-weight: 400;
        color: #373c41;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .confirm-order-footer {
    height: 87px;
    .pay-submit {
      box-sizing: border-box;
      width: 100%;
      height: 87px;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: space-around;
      background: #ffffff;
      padding: 14px 0 28px 0;
      &-cancle {
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 23px;
        border: 1px solid #8e8e93;
      }
      &-button {
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ff5500;
        border-radius: 23px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .order-detail {
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
    background: #fff;
    .header {
      position: static;
      .back-icon {
        margin-right: 12px;
      }
    }
    &-content {
      box-sizing: border-box;
      background: #fff;
      padding: 2%;
      .content-card {
        display: none;
      }
      .content-web {
        display: block;
        &-card {
          // width: 100%;
          height: 128px;
          border-bottom: 0.5px solid rgba(218, 218, 218, 0.5);
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          &-left {
            width: 40%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            .order-img {
              width: 126px;
              height: 80px;
              border-radius: 7px;
            }
            &-right {
              width: calc(100% - 126px - 12px);
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              padding: 11px 0;
              .right-title {
                font-size: 14px;
                font-weight: bold;
                color: #373c41;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              }
              .right-company {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .author {
                  font-size: 12px;
                  font-weight: 400;
                  color: #8e8e93;
                }
              }
            }
          }
          .pay-method {
            width: calc(60% / 6);
            height: 100%;
            text-align: center;
            padding: 11px 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &-orderType {
              width: 81px;
              height: 24px;
              line-height: 24px;
              background: #eeeeee;
              border-radius: 5px;
              font-size: 14px;
              font-weight: 400;
              color: #373c41;
            }
            &-price {
              width: 81px;
              text-align: center;
              span:nth-child(1) {
                font-size: 14px;
                font-weight: bold;
                color: #d30000;
              }
              span:nth-child(2) {
                font-size: 14px;
                color: #373c41;
              }
            }
          }

          .pay-state {
            width: calc(60% / 6);
            text-align: right;
          }
          .pay-operation {
            width: calc(60% / 6);
            display: flex;
            justify-content: center;
            .pay-button {
              width: 85px;
              height: 34px;
              line-height: 34px;
              text-align: center;
              background: #ff5500;
              border-radius: 61px;
              font-size: 14px;
              color: #ffffff;
              cursor: pointer;
            }
            .pay-detail {
              width: 85px;
              height: 34px;
              line-height: 34px;
              border-radius: 61px;
              border: 1px solid #ff5500;
              font-size: 14px;
              color: #ff5500;
              text-align: center;
              cursor: pointer;
            }
          }
        }
      }
      .pay-detail {
        padding: 0;
        .money-detail {
          justify-content: flex-end;
          border: none;
          span:nth-child(1) {
            margin-right: 341px;
          }
        }
        .total-money {
          border-bottom: 0.2px solid rgba(151, 151, 151, 0.2);
          span:nth-child(3) {
            margin-left: 20px;
          }
        }
      }
      .order-info {
        padding-right: 0;
        &-number {
          justify-content: flex-end;
          span:nth-child(1) {
            width: calc(100% - 390px);
            text-align: right;
          }
          span:nth-child(2) {
            width: 390px;
            text-align: right;
          }
        }
        &-time {
          justify-content: flex-end;
          span:nth-child(1) {
            width: calc(100% - 390px);
            text-align: right;
          }
          span:nth-child(2) {
            width: 390px;
            text-align: right;
          }
        }
        &-pay {
          justify-content: flex-end;
          span:nth-child(1) {
            width: calc(100% - 390px);
            text-align: right;
          }
          span:nth-child(2) {
            width: 390px;
            text-align: right;
          }
        }
      }
    }

    .confirm-order-footer {
      height: 87px;
      .pay-submit {
        position: static;
        justify-content: flex-end;
        padding: 0 24px;
        &-button {
          margin-left: 30px;
        }
      }
    }
  }
}
</style>
